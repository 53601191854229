import s from './AdminPostSection.module.css';
import smileIcon from '../../../assets/img/icons/smile-icon.svg';
import deleteIcon from '../../../assets/img/icons/delete-icon.svg';
import EmojiList from '../../../components/EmojiList/EmojiList';
import { createNewPost } from '../../../api/api.js';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../selectors/userSelectors.js';
import ModalWidnow from '../../../components/UI/ModalWidnow/ModalWidnow.jsx';
import Loader from '../../../components/UI/Loader/Loader.jsx';
import { Link } from 'react-router-dom';

const AdminPostSection = ({ newsId, data }) => {
	let [visibleStatus, setVisibleStatus] = useState(false);
	let [visibleStatus2, setVisibleStatus2] = useState(false);

	let [modalWidndovStatus, setModalWidndovStatus] = useState(false);
	let [modalWidndovStatusDelete, setModalWidndovStatusDelete] = useState(false);

	let [isFetching, setFetching] = useState(false);
	let [isFetchingStatus, setFetchingStatus] = useState(false);

	let [title, setTitle] = useState('');
	let [date, setDate] = useState('');
	let [text, setText] = useState('');
	let [tags, setTags] = useState('');
	let [images, setImages] = useState('');
	let [comment, setComment] = useState([]);

	let [validationErrors, setValidationErrors] = useState({ title: false, date: false, text: false, images: false });

	let ref = useRef();
	let userID = useSelector(userSelector.userData);

	const nulledAllInputs = () => {
		setTitle('');
		setDate('');
		setText('');
		setTags('');
		setImages('');
		setComment([]);
		ref.current.value = '';
	};
	const nulledAllValidations = () => {
		setValidationErrors({ title: false, date: false, text: false, images: false });
	};

	const creacteHandler = (e) => {
		e.preventDefault();

		// let data = {
		// 	title: title,
		// 	date: date,
		// 	tags: tags,
		// 	text: text,
		// 	imgUrl: '',
		// 	imgFile: '',
		// 	comments: [
		// 		{
		// 			autor: '',
		// 			comment_txt: '',
		// 			post_date: '',
		// 		},
		// 	],
		// };
		let data = {
			title: title,
			tags: tags.split(', '),
			date: date,
			text: text,
			imgFile: ref.current.files[0],
			cat_id: 1,
			autor_id: userID.id,
		};

		const fetchingNewPost = async () => {
			await setFetching(true);
			await setModalWidndovStatus(true);
			let result = await createNewPost(data);
			await setFetching(false);
			if (result.status == 200) {
				nulledAllInputs();
				setFetchingStatus(true);
			} else {
				setFetchingStatus(false);
			}
		};
		const validationForm = async () => {
			!title && setValidationErrors((obj) => ({ ...obj, title: true }));
			!date && setValidationErrors((obj) => ({ ...obj, date: true }));
			!text && setValidationErrors((obj) => ({ ...obj, text: true }));
			!ref.current.files[0] && setValidationErrors((obj) => ({ ...obj, images: true }));

			if (title && date && text && ref.current.files[0]) {
				nulledAllValidations();
				fetchingNewPost();
			}
		};
		validationForm();
	};
	const deleteOpenMoalHandler = (e) => {
		e.preventDefault();
		setModalWidndovStatusDelete(true);
	};

	useEffect(() => {
		console.log(validationErrors);
	}, [validationErrors]);
	useEffect(() => {
		setTitle(data.title);
		setDate(data.pub_date);
		data.tags && data.tags.length > 0 && setTags(data.tags.join(', '));
		setText(data.text);
		setImages(data.img);
		setComment(data.comment);
	}, [data]);

	return (
		<div className={`section-container`}>
			<form className={s.post}>
				<div className={s.post_item}>
					<p>
						Заголовок:
						<span style={{ fontSize: '20px', color: 'red' }} title="Обов`язкове">
							*
						</span>
					</p>
					<textarea
						value={title}
						onChange={(e) => setTitle(e.target.value)}
						placeholder="Заголовок"
						className={`${s.title} ${validationErrors.title ? s.validated : ''}`}></textarea>
					<button
						title="Смайлики"
						onClick={(e) => {
							e.preventDefault();
							setVisibleStatus((status) => !status);
						}}
						className={s.smile_button}>
						<img src={smileIcon} alt="" />
					</button>
					<EmojiList visibleStatus={visibleStatus} setSmile={setTitle} />
				</div>

				<div className={`${s.post_item} ${s.row}`}>
					<p>
						Дата публікації:
						<span style={{ fontSize: '20px', color: 'red' }} title="Обов`язкове">
							*
						</span>
					</p>
					<p className={`${s.date} ${validationErrors.date ? s.validated : ''}`}>
						<input type="datetime-local" step="1" onChange={(e) => setDate(e.target.value)} value={date} />
					</p>
				</div>

				<div className={s.post_item}>
					<p>Теги:</p>
					<input placeholder="Теги, теги, ..." type="text" value={tags} onChange={(e) => setTags(e.target.value)} />
				</div>

				<div className={s.post_item}>
					<p>
						Текст події:
						<span style={{ fontSize: '20px', color: 'red' }} title="Обов`язкове">
							*
						</span>
					</p>
					<textarea
						value={text}
						onChange={(e) => setText(e.target.value)}
						placeholder="Текст події"
						className={`${s.text} ${validationErrors.text ? s.validated : ''}`}></textarea>
					<button
						title="Смайлики"
						onClick={(e) => {
							e.preventDefault();
							setVisibleStatus2((status) => !status);
						}}
						className={s.smile_button}>
						<img src={smileIcon} alt="" />
					</button>
					<EmojiList visibleStatus={visibleStatus2} setSmile={setText} />
				</div>

				<div className={s.post_item}>
					<p>
						Зображення:
						<span style={{ fontSize: '20px', color: 'red' }} title="Обов`язкове">
							*
						</span>
					</p>

					{images && (
						<div className={s.image_container}>
							<img className={s.image_url} src={images} alt="" />
							<button
								onClick={(e) => {
									e.preventDefault();
									setImages('');
								}}
								title="Видалити зображення">
								<img src={deleteIcon} alt="" />
							</button>
						</div>
					)}

					<input
						ref={ref}
						className={`${s.image} ${!images && validationErrors.images ? s.validated : ''}`}
						type="file"
						name="myImage"
						placeholder="Оберіть зображення"
					/>
				</div>

				{comment && comment.length > 0 ? (
					<div className={s.post_item}>
						<p>Коментарі:</p>
						<div className={s.comments_list}>
							{comment.map((item) => (
								<div className={s.comment}>
									<div className={s.comment_column}>
										<p style={{ color: '#004795' }}>{item.autor}</p>
										<p style={{ color: '#7d7d7d', fontSize: '12px' }}>{item.post_date}</p>
										<p>{item.comment_txt}</p>
									</div>
									<button
										onClick={(e) => {
											e.preventDefault();

											setComment((el) => {
												let currentArray = [];
												el.forEach((element) => {
													if (element != item) {
														currentArray.push(element);
													}
												});
												console.log(currentArray);

												return currentArray;
											});
										}}
										title="Видалити коментар">
										<img src={deleteIcon} alt="" />
									</button>
								</div>
							))}
						</div>
					</div>
				) : (
					''
				)}

				<div className={s.select_buttons}>
					<Link to={'/admin-news-feed/'} className={s.cancel}>
						Відмінити
					</Link>
					{data.id && (
						<button className={s.delete} onClick={deleteOpenMoalHandler}>
							Видалити
						</button>
					)}
					<button className={s.select} onClick={creacteHandler}>
						Опублікувати
					</button>
				</div>
			</form>

			{modalWidndovStatus && (
				<ModalWidnow>
					<div className={s.result_window}>
						{isFetching ? (
							<Loader />
						) : (
							<div className={s.result_container}>
								{isFetchingStatus ? <p>Подія успішно створена!</p> : <p>Упс! Вийшла якась помилка!</p>}
								<button onClick={() => setModalWidndovStatus(false)}>Повернутися</button>
							</div>
						)}
					</div>
				</ModalWidnow>
			)}
			{modalWidndovStatusDelete && (
				<ModalWidnow>
					<div className={s.result_window}>
						{isFetching ? (
							<Loader />
						) : (
							<div className={s.result_container}>
								<p>Видалити цю подію?</p>
								<div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap' }}>
									<button onClick={() => setModalWidndovStatusDelete(false)}>Повернутися</button>
									<button onClick={() => alert('delete!')}>Видалити</button>
								</div>
							</div>
						)}
					</div>
				</ModalWidnow>
			)}
		</div>
	);
};

export default AdminPostSection;
